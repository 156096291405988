export function unionState(
  oldArray,
  newArray,
  oldField = "id",
  newField = "id",
  indexInsert = undefined
) {
  const newIds = newArray.map((newItem) => newItem[newField])
  if (!oldArray.length) {
    return newArray
  } else if (indexInsert) {
    let arr = oldArray.filter(
      (oldItem) => newIds.indexOf(oldItem[oldField]) === -1
    )
    arr.splice(indexInsert, 0, ...newArray)
    /// one more method for if page already exists. We need a way replace items by id in the array and do insertion that maintains correct order. For now im doing a sort

    return sortByField(arr)
  } else {
    return [
      ...oldArray.filter((oldItem) => newIds.indexOf(oldItem[oldField]) === -1),
      ...newArray,
    ]
  }
}

export function joinItem(oldArray, newItem, oldField = "id", newField = "id") {
  const index = oldArray.findIndex(
    (oldItem) => oldItem[oldField] === newItem[newField]
  )
  if (index > -1) oldArray[index] = newItem
  else oldArray.push(newItem)
  return oldArray
}

export function formatDate(date, withTime) {
  return withTime
    ? `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
    : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export function sortByField(array, field = "createdAt") {
  return array.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b[field]) - new Date(a[field])
  })
}

export function groupByAlphabet(
  array,
  field = "lastName",
  alternativeField = "firstName"
) {
  let resultObj = {}
  for (let i = 0; i < array.length; i++) {
    let currentObj = array[i]
    let firstCharOfField = currentObj[field]
      ? currentObj[field][0].toUpperCase()
      : currentObj[alternativeField]
      ? currentObj[alternativeField][0].toUpperCase()
      : ""
    let innerArr = []
    if (resultObj[firstCharOfField] === undefined) {
      innerArr.push(currentObj)
      resultObj[firstCharOfField] = innerArr
    } else {
      resultObj[firstCharOfField].push(currentObj)
    }
  }
  return resultObj
}

export function apiUrl(relative) {
  return import.meta.env.VITE_ROOT_API + relative
}
